import React from 'react';
import { Image } from 'antd';
import { Technology } from '../Data/Data';

interface TechnologiesBlockProps {
    data: Technology[];
}

export default ({ data }: TechnologiesBlockProps) => 
    <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-4/5 gap-20 mx-auto'>
        {data.map(({ name, img }) => <div key={name} className='flex flex-col gap-[10px] items-center'>
            <div className='text-lg'>{name}</div>
            <Image preview={false} src={img} alt="" width={128} height={128} />
        </div>)}
    </div>    
;