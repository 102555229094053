import React from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import FullPageImage from '../../Components/FullPageImage/FullPageImage';
import Banner from '../../Components/Banner';
import data from '../../Data/Data';
import TechnologiesBlock from '../../Components/TechnologiesBlock';
import TimelineBlock from '../../Components/TimelineBlock';
import './Home.css';

export default () => 
    <div className='h-screen'>
        <FullPageImage title='Web Developer' />
        <Banner color='light'>
            <div className='main-title'>Rémy Marchiol</div>
            <div className='main-description w-full lg:w-4/5 m-auto'>
                Passionné par le développement web depuis plus de quatre ans,
                je suis déterminé à transformer des lignes de code en expériences interactives
                exceptionnelles. En dehors du monde du développement, je trouve mon inspiration
                dans les univers captivants des jeux vidéo et des jeux de cartes. Bienvenue
                sur mon portfolio, où chaque projet raconte une histoire unique de compétence 
                technique et de créativité immersive.
            </div>
        </Banner>
        <Banner color='dark'>
            <div className='main-title'>Technologies</div>
            <div className='main-description'>
                <TechnologiesBlock data={data.technologies} />
            </div>
        </Banner>
        <Banner color='light'>
            <div className='grid 2xl:grid-cols-2 grid-cols-1 w-full lg:w-4/5 m-auto gap-1/5'>
                <div className='flex flex-col gap-[40px] 2xl:items-center items-start'>
                    <div className='tracking-[2px] font-medium text-3xl mx-auto'>Formations</div>
                    <div className='items-center'>
                        <TimelineBlock data={data.timelineEducation} />
                    </div>
                </div>
                <div className='flex flex-col gap-[40px] 2xl:items-center items-start'>
                    <div className='tracking-[2px] font-medium text-3xl mx-auto'>Expériences professionnelles</div>
                    <TimelineBlock data={data.timelineExperiences} />
                </div>    
            </div>    
        </Banner>
        <Banner color='dark'>
            <div className='main-title'>Mes projets</div>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 w-full lg:w-4/5 gap-20 mx-auto'>
                {data.projects.map(({ name, imageUrl, link }) => <div key={name} className='flex flex-col gap-[10px] items-center project'>
                    <div className='text-lg'>{name}</div>
                    <Link key={uuidv4()} className='project' to={link}>
                        <Image preview={false} src={imageUrl} alt="" width={256} height={256} />
                    </Link>
                </div> )}
            </div>
        </Banner>
    </div>
;