export interface Data {
    technologies: Technology[];
    timelineEducation: TimelineBlock[];
    timelineExperiences: TimelineBlock[];
    projects: Project[];
}

export interface Technology {
    name: string;
    img: string;
}

export interface TimelineBlock {
    title: string;
    subtitle: string;
    date: string;
}

export interface Project {
    link: string;
    name: string;
    imageUrl: string;
}

const data: Data = {
    technologies: [
        {
            name: 'ReactJS',
            img: '/assets/svg/technologies/react.svg',
        },
        {
            name: 'HTML 5',
            img: '/assets/svg/technologies/html.svg'
        },
        {
            name: 'CSS 3',
            img: '/assets/svg/technologies/css-3.svg'
        },
        {
            name: 'JS',
            img: '/assets/svg/technologies/javascript.svg'
        },
        {
            name: 'Typescript',
            img: '/assets/svg/technologies/typescript.svg',
        },
        {
            name: 'Tailwind',
            img: '/assets/svg/technologies/tailwind-css.svg'
        },
        {
            name: 'Ant Design',
            img: '/assets/svg/technologies/ant-design.svg'
        },
        {
            name: 'Php',
            img: '/assets/svg/technologies/php.svg'
        },
        {
            name: 'Symfony',
            img: '/assets/svg/technologies/symfony.svg'
        },
        {
            name: 'Strapi',
            img: '/assets/svg/technologies/strapi.svg'
        },
        {
            name: 'Docker',
            img: '/assets/svg/technologies/docker.svg'
        },
        {
            name: 'Git',
            img: '/assets/svg/technologies/git.svg'
        },
    ],
    timelineEducation: [
        {
            title: 'Licence professionnelle web et commerce électronique',
            subtitle: 'IUT de Metz',
            date: '2018-2019',
        },
        {
            title: 'DUT Informatique - Année spéciale',
            subtitle: 'IUT de Metz',
            date: '2017-2018',
        },
        {
            title: 'DUT GEA',
            subtitle: 'IUT de Longwy',
            date: '2015-2017',
        },
        {
            title: 'Baccalauréat STI2D',
            subtitle: 'Lycée La Briquerie (Thionville)',
            date: '2012-2013'
        }
    ],
    timelineExperiences: [
        {
            title: 'Front Web Developer',
            subtitle: 'Nvision Agency',
            date: 'Novembre 2023 - Aujourd\'hui',
        },
        {
            title: 'Fullstack Web Developer - ReactJS, PHP, jQuery',
            subtitle: 'MaPS System',
            date: 'Août 2019 - Juin 2023'
        },
        {
            title: 'Stage en développement web',
            subtitle: 'MaPS System',
            date: 'Avril 2018 - Juillet 2019'
        },
    ],
    projects: [
        {
            link: '/roguelike',
            name: 'Roguelike',
            imageUrl: '/assets/images/projects/roguelike/thumbnail.png'
        },
        {
            link: '/caverne',
            name: 'Caverne',
            imageUrl: '/assets/images/projects/scraper/thumbnail.jpg'
        },
    ]
};

export default data;