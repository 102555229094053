import React from 'react';
import './FullPageImage.css';

interface FullPageImageProps {
    title?: string;
}

export default ({ title }: FullPageImageProps) => <div className="full-page-image-container">
    {title && <div className="full-page-image-caption">
        <span className="full-page-image-span">{title}</span>
    </div>}
</div>;
